import { Chain } from 'wagmi'
 
export const pulsechain = {
  id: 369,
  name: 'PulseChain',
  network: 'PulseChain',
  nativeCurrency: {
    decimals: 18,
    name: 'Pulsechain Token',
    symbol: 'PLS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.pulsechain.com'] },
    default: { http: ['https://rpc.pulsechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'PLS', url: 'https://scan.pulsechain.com' },
    default: { name: 'PLS', url: 'https://scan.pulsechain.com' },
  },
}