export const CONTRACTS = {
  PulsefinityToken: "PulsefinityToken",
  PulsefinitySacrifice: "PulsefinitySacrifice",
  PulsefinityPriceDiscovery: "PulsefinityPriceDiscovery",
  PulsefinityVesting: "PulsefinityVesting",
  OldPulsefinityStakingRouter: "OldPulsefinityStakingRouter",
  PulsefinityStakingRouter: "PulsefinityStakingRouter",
  PulsefinityLaunchpad: "PulsefinityLaunchpad",
  stakingPools: {
    Oldplf: "Oldplf",
    plf: "plf",
  },
  FairLaunchIco: "FairLaunchIco",
  BABYTOKENDividendsTracker: "BABYTOKENDividendsTracker",
  ERC20: "ERC20",
  PulseXFactory: "PulseXFactory",
  WPLSX: "WPLSX",
  PulsefinityOldToken: "PulsefinityOldToken",
  TokenVesting: "TokenVesting",
};

export const TOKEN_FACTORIES = {
  StandardToken: "StandardToken",
  BabyToken: "BabyToken",
  BuybackBabyToken: "BuybackBabyToken",
  LiquidityGeneratorToken: "LiquidityGeneratorToken",
  AntiBotStandardToken: "AntiBotStandardToken",
  AntiBotBabyToken: "AntiBotBabyToken",
  AntiBotBuybackBabyToken: "AntiBotBuybackBabyToken",
  AntiBotLiquidityGeneratorToken: "AntiBotLiquidityGeneratorToken",
};

export const CONTRACT_FACTORIES = {
  FairlaunchIco: "FairlaunchIco",
};

export const CONTRACT_ADDRESSES = {
  11155111: {
    [CONTRACTS.PulsefinityOldToken]:
      "0x3AF39486398c1dE06af12F4C14d3C77D70e65d36",
    [CONTRACTS.PulsefinityToken]: "0x3AF39486398c1dE06af12F4C14d3C77D70e65d36",
    [CONTRACTS.TokenVesting]: "0xcf89B85a7cF1792434a15d7AA39735b585E3FC0b",
    [CONTRACTS.PulsefinityVesting]:
      "0xFe4c90d73D03738D6584429Ec51d28c3eaC8076D",
    [CONTRACTS.PulsefinityPriceDiscovery]:
      "0x0857695b30f7626acf2f9Eb41C1A101375CD4b58",
    [CONTRACTS.OldPulsefinityStakingRouter]:
      "0xAc055b325DA2CD4544Aeb39f6745f2a705F4EA0A",
    [CONTRACTS.PulsefinityStakingRouter]:
      "0xAc055b325DA2CD4544Aeb39f6745f2a705F4EA0A",
    [CONTRACTS.PulsefinityLaunchpad]:
      "0xDC25536cd5C5A70e43F5A85504807eAC308047CE",
    [CONTRACTS.stakingPools.Oldplf]:
      "0x0e8D4dC18D519B9a8ac43b312CA76934e188202F",
    [CONTRACTS.stakingPools.plf]: "0x0e8D4dC18D519B9a8ac43b312CA76934e188202F",
  },
  1337: {
    [CONTRACTS.PulsefinityToken]: "0x54e56D8FEfD66840192809d147dd5eaacba5F5EB",
    [CONTRACTS.PulsefinitySacrifice]: "",
    [CONTRACTS.PulsefinityPriceDiscovery]:
      "0xBb603E8458E1FfFf9434bd565F9a89a02027fd91",
    [CONTRACTS.PulsefinityVesting]:
      "0xa1701cD0D0c90a4D18e85fe7B2fb795E15B8fAFB",
    [CONTRACTS.PulsefinityStakingRouter]:
      "0xe6Eea3fC1b0B850C888C26cf803Ab862A9cC481B",
    [CONTRACTS.PulsefinityLaunchpad]:
      "0x3833acea992090D132bF78Eb2b2EA5516D443281",
    [CONTRACTS.stakingPools.plf]: "0x2819824221cE7cbef6b5dD84d24CFD265483B5c3",
    [CONTRACTS.BABYTOKENDividendsTracker]:
      "0xEbf0173A9257c4c1Bc64b83DC6Ba99549FeCb2d9",
    [CONTRACTS.PulseXFactory]: "0x1715a3e4a142d8b698131108995174f37aeba10d",
    [CONTRACTS.WPLSX]: "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  },
  943: {
    [CONTRACTS.PulsefinityToken]: "0x6Ce29a8fe3B3f200636153A50ADdDc6E48CA0F59",
    // [CONTRACTS.PulsefinityToken]: "0x7B6a0b709c2847Ea445a1a87cb97bfd764B7b90e",
    [CONTRACTS.PulsefinitySacrifice]: "",
    [CONTRACTS.PulsefinityPriceDiscovery]:
      "0xbe40c8ad9f361c5FEa53B765C2fdf95b6cd235EC",
    [CONTRACTS.PulsefinityVesting]:
      "0x4060708149e6EC5E03801d2C33EC7B21c0a3c9f0",
    [CONTRACTS.PulsefinityStakingRouter]:
      "0xD04E1FE41E0DbCf724Bde7738a4a6D1188C957Cc",
    [CONTRACTS.PulsefinityLaunchpad]:
      "0xAB88C32218F72dC6d49Bd0e903F888F0a68599a3",
    [CONTRACTS.stakingPools.plf]: "0x543A57DC66189F8cB917e7b54BD9Cd156FC9DF0D",
    [CONTRACTS.BABYTOKENDividendsTracker]:
      "0xe89e9939A8941A57511F3DdD32d0C8440E3424c2",
    [CONTRACTS.PulseXFactory]: "0xff0538782d122d3112f75dc7121f61562261c0f7",
    [CONTRACTS.WPLSX]: "0x70499adebb11efd915e3b69e700c331778628707",
  },
  369: {
    [CONTRACTS.PulsefinityOldToken]:
      "0x33f687901720336f1947cF5c4E0804308AE57A99",
    [CONTRACTS.PulsefinityToken]: "0x703dA3bd4Cb11db5eeD96e4b476942b9DF871736",
    [CONTRACTS.TokenVesting]: "0x9aED5fc621313B0EA32BC11FA9A24f23D73c470a",
    [CONTRACTS.PulsefinitySacrifice]: "",
    [CONTRACTS.PulsefinityPriceDiscovery]:
      "0x59a9317e75D1dFF8C8Ec249dbc18e37E090BD578",
    [CONTRACTS.PulsefinityVesting]:
      "0x9F876248FB3d333f51893ec4FaCAEcEDf87f89A6",
    [CONTRACTS.OldPulsefinityStakingRouter]:
      "0x641BFEdDC36bCf4d5FAE269f7726385BA42B0F98",
    [CONTRACTS.PulsefinityStakingRouter]:
      "0x54B103f37dA2E89695360438Bc76B62C271AFDe3",
    [CONTRACTS.PulsefinityLaunchpad]:
      "0xC8d616816Ac36fe217E61e452F170fe4737F2763",
    [CONTRACTS.stakingPools.Oldplf]:
      "0x16E2ADc9d0D898d3046E07DcF711dc1039A4857C",
    [CONTRACTS.stakingPools.plf]: "0x68EdF798816d27C27C7a406B08Cf0238dD3cF750",
    [CONTRACTS.BABYTOKENDividendsTracker]:
      "0xD22B465356aA856A03e3b4fc6bB2e1b3438A5831",
    [CONTRACTS.PulseXFactory]: "0x1715a3e4a142d8b698131108995174f37aeba10d",
    [CONTRACTS.WPLSX]: "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  },
};

export const CONTRACT_TOKEN_VESTING_ABI = [
  {
    inputs: [
      { internalType: "address", name: "Oldtoken_", type: "address" },
      { internalType: "address", name: "NewToken_", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  { stateMutability: "payable", type: "fallback" },
  {
    inputs: [],
    name: "_NewToken",
    outputs: [{ internalType: "contract ERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "_OldToken",
    outputs: [{ internalType: "contract ERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "tokenOwner", type: "address" }],
    name: "getClaimableAmount",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "migrateToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalTokenClaimed",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export const CONTRACT_WRITE_METHODS = {
  [CONTRACTS.PulsefinityToken]: {
    approve: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "approve",
    },
  },
  [CONTRACTS.PulsefinitySacrifice]: {
    claimSacrificePoints: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
            {
              internalType: "bytes32[]",
              name: "merkleProof",
              type: "bytes32[]",
            },
          ],
          name: "claimSacrificePoints",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "claimSacrificePoints",
    },
  },
  [CONTRACTS.FairLaunchIco]: {
    buy: {
      abi: [
        {
          inputs: [],
          name: "buy",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      functionName: "buy",
    },
    endSale: {
      abi: [
        {
          inputs: [],
          name: "endSale",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "endSale",
    },
    claim: {
      abi: [
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_buyers",
              type: "address[]",
            },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "claim",
    },
  },
  [CONTRACTS.PulsefinityPriceDiscovery]: {
    deposit: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "identifier",
              type: "address",
            },
          ],
          name: "deposit",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      functionName: "deposit",
    },
    withdraw: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "identifier",
              type: "address",
            },
          ],
          name: "withdraw",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "withdraw",
    },
    claimLockedTokens: {
      abi: [
        {
          inputs: [],
          name: "claimLockedTokens",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "claimLockedTokens",
    },
    addLiquidity: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "plfAmountFromWallet",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "plfAmountLocked",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "plsAmountFromWallet",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "plsAmountLocked",
              type: "uint256",
            },
          ],
          name: "addLiquidity",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      functionName: "addLiquidity",
    },
    nextStage: {
      abi: [
        {
          inputs: [],
          name: "nextStage",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "nextStage",
    },
  },
  [CONTRACTS.PulsefinityVesting]: {
    release: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_token",
              type: "address",
            },
            {
              internalType: "address",
              name: "_beneficiary",
              type: "address",
            },
          ],
          name: "release",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "release",
    },
  },
  [CONTRACTS.PulsefinityStakingRouter]: {},
  [CONTRACTS.PulsefinityLaunchpad]: {
    createSale: {
      abi: [
        {
          inputs: [
            {
              components: [
                {
                  internalType: "contract IERC20Metadata",
                  name: "token",
                  type: "address",
                },
                {
                  internalType: "contract IERC20Metadata",
                  name: "paymentToken",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "owner",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "tokenAmount",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "price",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "softCap",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "hardCap",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "liquidityPercentage",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "listingPrice",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "liquidityLockupTime",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "startTimestamp",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "endTimestamp",
                  type: "uint256",
                },
                {
                  internalType: "bool",
                  name: "refundType",
                  type: "bool",
                },
                {
                  internalType: "bool",
                  name: "isVestedSale",
                  type: "bool",
                },
                {
                  internalType: "uint256",
                  name: "tgeUnlockPercentage",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "vestingStart",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "vestingDuration",
                  type: "uint256",
                },
                {
                  internalType: "enum IVestingContract.DurationUnits",
                  name: "vestingDurationUnits",
                  type: "uint8",
                },
              ],
              internalType: "struct PulsefinityLaunchpad.SaleParams",
              name: "_saleParams",
              type: "tuple",
            },
          ],
          name: "createSale",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "createSale",
    },
    contribute: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
          ],
          name: "contribute",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      functionName: "contribute",
    },
    claim: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ],
      functionName: "claim",
    },
    endSale: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
          ],
          name: "endSale",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "endSale",
    },
    enableSale: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
          ],
          name: "enableSale",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "enableSale",
    },
  },
  [CONTRACTS.stakingPools.plf]: {
    stake: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "enum PulsefinityStakingPool.LockType",
              name: "_lockType",
              type: "uint8",
            },
          ],
          name: "stake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "stake",
    },
    migrate_stake: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
          ],
          name: "migrate_stake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "migrate_stake",
    },
    withdraw: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "stakeIndex",
              type: "uint256",
            },
          ],
          name: "withdraw",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      functionName: "withdraw",
    },
  },
};

export const CONTRACT_READ_METHODS = {
  [CONTRACTS.PulsefinityToken]: {
    allowance: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "spender",
              type: "address",
            },
          ],
          name: "allowance",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "allowance",
    },
    balanceOf: {
      abi: [
        {
          constant: true,
          inputs: [
            {
              name: "_owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              name: "balance",
              type: "uint256",
            },
          ],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "balanceOf",
    },
  },
  [CONTRACTS.FairLaunchIco]: {
    totalRaised: {
      abi: [
        {
          inputs: [],
          name: "totalRaised",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "totalRaised",
    },
    contributions: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "contributions",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "contributions",
    },
    softCapReached: {
      abi: [
        {
          inputs: [],
          name: "softCapReached",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "softCapReached",
    },
    saleEnded: {
      abi: [
        {
          inputs: [],
          name: "saleEnded",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "saleEnded",
    },
  },
  [CONTRACTS.PulsefinityPriceDiscovery]: {
    plfDeposits: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "plfDeposits",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "plfDeposits",
    },
    plsDeposits: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "plsDeposits",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "plsDeposits",
    },
    totalSupplyPLF: {
      abi: [
        {
          inputs: [],
          name: "totalSupplyPLF",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "totalSupplyPLF",
    },
    totalSupplyPLS: {
      abi: [
        {
          inputs: [],
          name: "totalSupplyPLS",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "totalSupplyPLS",
    },
    pricePLF: {
      abi: [
        {
          inputs: [],
          name: "pricePlf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "pricePlf",
    },
    getLockedPLF: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "user",
              type: "address",
            },
          ],
          name: "getLockedPLF",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getLockedPLF",
    },
    getLockedPLS: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "user",
              type: "address",
            },
          ],
          name: "getLockedPLS",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getLockedPLS",
    },
    getWithdrawPenalty: {
      abi: [
        {
          inputs: [],
          name: "getWithdrawPenalty",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getWithdrawPenalty",
    },
    liquidityPhaseStarted: {
      abi: [
        {
          inputs: [],
          name: "liquidityPhaseStarted",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "liquidityPhaseStarted",
    },
    tradingPhaseStarted: {
      abi: [
        {
          inputs: [],
          name: "tradingPhaseStarted",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "tradingPhaseStarted",
    },
    priceDiscoveryStartTime: {
      abi: [
        {
          inputs: [],
          name: "priceDiscoveryStartTime",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "priceDiscoveryStartTime",
    },
    liquidityPhaseStartTime: {
      abi: [
        {
          inputs: [],
          name: "liquidityPhaseStartTime",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "liquidityPhaseStartTime",
    },
    tradingPhaseStartTime: {
      abi: [
        {
          inputs: [],
          name: "tradingPhaseStartTime",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "tradingPhaseStartTime",
    },
  },
  [CONTRACTS.PulsefinityVesting]: {
    getVestingSchedules: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_token",
              type: "address",
            },
            {
              internalType: "address",
              name: "_beneficiary",
              type: "address",
            },
          ],
          name: "getVestingSchedules",
          outputs: [
            {
              components: [
                {
                  internalType: "address",
                  name: "beneficiary",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "start",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "duration",
                  type: "uint256",
                },
                {
                  internalType: "enum IVestingContract.DurationUnits",
                  name: "durationUnits",
                  type: "uint8",
                },
                {
                  internalType: "uint256",
                  name: "amountTotal",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "released",
                  type: "uint256",
                },
              ],
              internalType: "struct IVestingContract.VestingSchedule[]",
              name: "",
              type: "tuple[]",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getVestingSchedules",
    },
    getReleaseableAmount: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_token",
              type: "address",
            },
            {
              internalType: "address",
              name: "_beneficiary",
              type: "address",
            },
          ],
          name: "getReleaseableAmount",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getReleaseableAmount",
    },
  },
  [CONTRACTS.PulsefinityStakingRouter]: {
    tierLimits: {
      abi: [
        {
          inputs: [],
          name: "tierLimits",
          outputs: [
            {
              internalType: "uint256",
              name: "nano",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "micro",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "mega",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "giga",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "tera",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "teraPlus",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "tierLimits",
    },
    getTotalStaked: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getTotalStaked",
          outputs: [
            {
              internalType: "uint256",
              name: "totalStaked",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getTotalStaked",
    },
    getTier: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getTier",
          outputs: [
            {
              internalType: "enum Tier",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getTier",
    },
  },
  [CONTRACTS.PulsefinityLaunchpad]: {
    getTierAllocation: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
            {
              internalType: "enum Tier",
              name: "_buyerTier",
              type: "uint8",
            },
          ],
          name: "getTierAllocation",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getTierAllocation",
    },
    saleIdTracker: {
      abi: [
        {
          inputs: [],
          name: "saleIdTracker",
          outputs: [
            {
              internalType: "uint256",
              name: "_value",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "saleIdTracker",
    },
    tierWeights: {
      abi: [
        {
          inputs: [
            {
              internalType: "enum Tier",
              name: "",
              type: "uint8",
            },
          ],
          name: "tierWeights",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "tierWeights",
    },
    sales: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          name: "sales",
          outputs: [
            {
              internalType: "bool",
              name: "saleEnabled",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "softCapReached",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "saleEnded",
              type: "bool",
            },
            {
              internalType: "uint256",
              name: "totalPaymentTokenContributed",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "totalTokensSold",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "totalTokensForLiquidity",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "liquidityUnlockTimestamp",
              type: "uint256",
            },
            {
              components: [
                {
                  internalType: "contract IERC20Metadata",
                  name: "token",
                  type: "address",
                },
                {
                  internalType: "contract IERC20Metadata",
                  name: "paymentToken",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "owner",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "tokenAmount",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "price",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "softCap",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "hardCap",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "liquidityPercentage",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "listingPrice",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "liquidityLockupTime",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "startTimestamp",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "endTimestamp",
                  type: "uint256",
                },
                {
                  internalType: "bool",
                  name: "refundType",
                  type: "bool",
                },
                {
                  internalType: "bool",
                  name: "isVestedSale",
                  type: "bool",
                },
                {
                  internalType: "uint256",
                  name: "tgeUnlockPercentage",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "vestingStart",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "vestingDuration",
                  type: "uint256",
                },
                {
                  internalType: "enum IVestingContract.DurationUnits",
                  name: "vestingDurationUnits",
                  type: "uint8",
                },
              ],
              internalType: "struct PulsefinityLaunchpad.SaleParams",
              name: "saleParams",
              type: "tuple",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "sales",
    },
    amountContributed: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          name: "amountContributed",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "amountContributed",
    },
    getTierAllocation: {
      abi: [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_saleId",
              type: "uint256",
            },
            {
              internalType: "enum Tier",
              name: "_buyerTier",
              type: "uint8",
            },
          ],
          name: "getTierAllocation",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getTierAllocation",
    },
  },
  [CONTRACTS.stakingPools.plf]: {
    isNativeToken: {
      abi: [
        {
          inputs: [],
          name: "isNativeToken",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "isNativeToken",
    },
    requiredTier: {
      abi: [
        {
          inputs: [],
          name: "requiredTier",
          outputs: [
            {
              internalType: "enum Tier",
              name: "",
              type: "uint8",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "requiredTier",
    },
    getUserStakes: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_user",
              type: "address",
            },
          ],
          name: "getUserStakes",
          outputs: [
            {
              components: [
                {
                  internalType: "uint256",
                  name: "amount",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "shares",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "rewardIndex",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "startTimestamp",
                  type: "uint256",
                },
                {
                  internalType: "enum PulsefinityStakingPool.LockType",
                  name: "lockType",
                  type: "uint8",
                },
              ],
              internalType: "struct PulsefinityStakingPool.Stake[]",
              name: "",
              type: "tuple[]",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getUserStakes",
    },
    rewardIndex: {
      abi: [
        {
          inputs: [],
          name: "rewardIndex",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "rewardIndex",
    },
    totalStaked: {
      abi: [
        {
          inputs: [],
          name: "totalStaked",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "totalStaked",
    },
  },
  [CONTRACTS.PulseXFactory]: {
    getPair: {
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "tokenA",
              type: "address",
            },
            {
              internalType: "address",
              name: "tokenB",
              type: "address",
            },
          ],
          name: "getPair",
          outputs: [
            {
              internalType: "address",
              name: "pair",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      functionName: "getPair",
    },
  },
};

export const CONTRACT_EVENTS = {
  [CONTRACTS.PulsefinityLaunchpad]: {
    SaleCreated: {
      abi: [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "saleId",
              type: "uint256",
            },
          ],
          name: "SaleCreated",
          type: "event",
        },
      ],
      eventName: "SaleCreated",
    },
  },
};

export const CUSTOM_ABIS = {
  [CONTRACTS.ERC20]: [
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [
        {
          name: "",
          type: "string",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_spender",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          name: "",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_from",
          type: "address",
        },
        {
          name: "_to",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [
        {
          name: "",
          type: "uint8",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          name: "balance",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [
        {
          name: "",
          type: "string",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_to",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
        {
          name: "_spender",
          type: "address",
        },
      ],
      name: "allowance",
      outputs: [
        {
          name: "",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      payable: true,
      stateMutability: "payable",
      type: "fallback",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    // ERC-20 Extensions
    // UUID
    {
      inputs: [],
      name: "uuid",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    // ERC-20 Extensions
    // BabyToken
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "excludeFromFees",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    // Babytoken Buyback
    {
      inputs: [
        {
          internalType: "address",
          name: "holder",
          type: "address",
        },
      ],
      name: "setIsFeeExempt",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    // Liquidity Token
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "excludeFromFee",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
};
