import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ENDPOINTS, API_URL } from "../config/api";
import { 
  setAllTokens, 
  setUserTokens
} from "./launchTokens.slice"
import { 
  setUpcoming,
  setActive,
  setClosed,
  setByUser,
  setById
} from "./launchpads.slice"
import { 
  setUpcomingPresale,
  setActivePresale,
  setClosedPresale,
  setPresaleByUser,
  setPresaleById
} from "./presales.slice"
import { 
  setUser,
  setSacrifice
} from "./user.slice"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    // USER endpoints
    getUser: builder.query({
      query: ({ wallet_address }) => ({
        ...API_ENDPOINTS.USER.GET,
        params: { wallet_address }
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setUser(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    setUsername: builder.mutation({
      query: ({ wallet_address, username }) => ({
        ...API_ENDPOINTS.USER.SET.USERNAME,
        body: { wallet_address, username },
      }),
    }),
    setAvatar: builder.mutation({
      query: ({ wallet_address, image_data }) => ({
        ...API_ENDPOINTS.USER.SET.AVATAR,
        body: { wallet_address, image_data },
      }),
    }),
    // LAUNCH_TOKENS endpoints
    getAllLaunchTokens: builder.query({
      query: () => ({
        ...API_ENDPOINTS.LAUNCH_TOKEN.GET_ALL,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setAllTokens(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getByUserLaunchTokens: builder.query({
      query: ({ creator_address }) => ({
        ...API_ENDPOINTS.LAUNCH_TOKEN.GET_ALL,
        params: { creator_address },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setUserTokens(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    registerLaunchToken: builder.mutation({
      query: ({ 
        launchTokenData
      }) => {
        return {
          ...API_ENDPOINTS.LAUNCH_TOKEN.REGISTER,
          body: {
            ...launchTokenData
          },
        };
      },
    }),
    setLogo: builder.mutation({
      query: ({
        token_address,
        image_data
      }) => ({
        ...API_ENDPOINTS.LAUNCH_TOKEN.SET_LOGO,
        body: { token_address, image_data },
      }),
    }),
    // SACRIFICE endpoints
    getClaimData: builder.query({
      query: ({ wallet_address }) => ({
        ...API_ENDPOINTS.SACRIFICE.GET_CLAIM_DATA,
        params: { wallet_address },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          if (data && data.data && data.data.success && data.data.data) {
            dispatch(setSacrifice(data.data.data));
          }
        } catch (error) {
          console.error(error);
        }
      },
    }),
    confirmSacrificeClaim: builder.mutation({
      query: ({ transaction_hash }) => ({
        ...API_ENDPOINTS.SACRIFICE.CONFIRM_SACRIFICE_CLAIM,
        body: { transaction_hash },
      }),
    }),
    // FAIRLAUNCH_API endpoints
    registerFairLaunch: builder.mutation({
      query: ({ launchData }) => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.REGISTER,
        body: { ...launchData },
      }),
    }),
    setFairLaunchLogo: builder.mutation({
      query: ({ launchpadId, image_data }) => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.SET_LOGO,
        body: { launchpadId, image_data },
      }),
    }),
    setFairLaunchBanner: builder.mutation({
      query: ({ launchpadId, image_data }) => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.SET_BANNER,
        body: { launchpadId, image_data },
      }),
    }),
    getUpcomingLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.GET_UPCOMING_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setUpcoming(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getActiveLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.GET_ACTIVE_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setActive(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getClosedLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.GET_CLOSED_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setClosed(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getLaunchpadsByUser: builder.query({
      query: ({ address_creator }) => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.GET_LAUNCHPADS_BY_USER,
        params: { address_creator },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setByUser(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getLaunchpadById: builder.query({
      query: ({ launchpadId }) => ({
        ...API_ENDPOINTS.FAIRLAUNCH_API.GET_LAUNCHPAD_BY_ID,
        params: { launchpadId },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setById(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    // PRESALE endpoints
    registerPresale: builder.mutation({
      query: ({ launchData }) => ({
        ...API_ENDPOINTS.PRESALE.REGISTER,
        body: { ...launchData },
      }),
    }),
    setPresaleLogo: builder.mutation({
      query: ({ launchpadId, image_data }) => ({
        ...API_ENDPOINTS.PRESALE.SET_LOGO,
        body: { launchpadId, image_data },
      }),
    }),
    setPresaleBanner: builder.mutation({
      query: ({ launchpadId, image_data }) => ({
        ...API_ENDPOINTS.PRESALE.SET_BANNER,
        body: { launchpadId, image_data },
      }),
    }),
    getUpcomingPresaleLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.PRESALE.GET_UPCOMING_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setUpcomingPresale(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getActivePresaleLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.PRESALE.GET_ACTIVE_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setActivePresale(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getClosedPresaleLaunchpads: builder.query({
      query: () => ({
        ...API_ENDPOINTS.PRESALE.GET_CLOSED_LAUNCHPADS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setClosedPresale(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getPresaleLaunchpadsByUser: builder.query({
      query: ({ address_creator }) => ({
        ...API_ENDPOINTS.PRESALE.GET_LAUNCHPADS_BY_USER,
        params: { address_creator },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setPresaleByUser(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getPresaleLaunchpadById: builder.query({
      query: ({ launchpadId }) => ({
        ...API_ENDPOINTS.PRESALE.GET_LAUNCHPAD_BY_ID,
        params: { launchpadId },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          dispatch(setPresaleById(data.data.data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updatePresaleStatus: builder.mutation({
      query: ({ sale_id, status }) => ({
        ...API_ENDPOINTS.PRESALE.UPDATE.STATUS,
        body: { sale_id, status },
      }),
    }),
  }),
});

export const {
  // User
  useGetUserQuery,
  useSetUsernameMutation,
  useSetAvatarMutation,
  // Tokens
  useGetAllLaunchTokensQuery,
  useGetByUserLaunchTokensQuery,
  useRegisterLaunchTokenMutation,
  useSetLogoMutation,
  // Sacrifice
  useGetClaimDataQuery,
  useConfirmSacrificeClaimMutation,
  // Fairlaunch
  useRegisterFairLaunchMutation,
  useSetFairLaunchLogoMutation,
  useSetFairLaunchBannerMutation,
  useGetUpcomingLaunchpadsQuery,
  useGetActiveLaunchpadsQuery,
  useGetClosedLaunchpadsQuery,
  useGetLaunchpadsByUserQuery,
  useGetLaunchpadByIdQuery,
  // Presale
  useRegisterPresaleMutation,
  useSetPresaleLogoMutation,
  useSetPresaleBannerMutation,
  useGetUpcomingPresaleLaunchpadsQuery,
  useGetActivePresaleLaunchpadsQuery,
  useGetClosedPresaleLaunchpadsQuery,
  useGetPresaleLaunchpadsByUserQuery,
  useGetPresaleLaunchpadByIdQuery,
  useUpdatePresaleStatusMutation
} = apiSlice;
