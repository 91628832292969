import { createSlice } from '@reduxjs/toolkit'
import { 
  FORM_TYPES, 
  FORM_FIELDS
} from '../constants';
import { TOKEN_FACTORIES } from 'config/web3_contracts';
import {
  pulsexRouter
} from "config/app"

const fairLaunchForm = {
  // Token Info
  [FORM_FIELDS.NAME]: null,
  [FORM_FIELDS.SYMBOL]: null,
  [FORM_FIELDS.DECIMALS]: null,
  [FORM_FIELDS.TOTAL_SUPPLY]: null,
  [FORM_FIELDS.BALANCE_OF]: null,
  [FORM_FIELDS.UUID]: null,
  // Launchpad Info
  [FORM_FIELDS.PAYMENT_TOKEN]: null,
  [FORM_FIELDS.LAUNCH_TOKEN]: null,
  [FORM_FIELDS.LAUNCH_TOKEN_AMOUNT]: null,
  //[FORM_FIELDS.LAUNCH_FAIR_PRICE]: null,
  [FORM_FIELDS.LAUNCH_START_TIME]: null,
  [FORM_FIELDS.LAUNCH_END_TIME]: null,
  [FORM_FIELDS.LAUNCH_LIQUIDITY_PERCENTAGE]: null,
  [FORM_FIELDS.LAUNCH_LIQUIDITY_LOCKUP_DURATION]: null,
  [FORM_FIELDS.LAUNCH_SOFT_CAP]: null,
  [FORM_FIELDS.LAUNCH_MIN_CONTRIBUTION]: null,
  [FORM_FIELDS.LAUNCH_MAX_CONTRIBUTION]: null,
  // Documents
  [FORM_FIELDS.WHITE_PAPER]: null,
  [FORM_FIELDS.KYC]: null,
  [FORM_FIELDS.AUDIT]: null,
  // Socials
  [FORM_FIELDS.WEBSITE]: null,
  [FORM_FIELDS.LOGO_FILE]: null,
  [FORM_FIELDS.BANNER_FILE]: null,
  [FORM_FIELDS.TWITTER]: null,
  [FORM_FIELDS.TELEGRAM]: null,
  [FORM_FIELDS.DISCORD]: null,
  [FORM_FIELDS.INSTAGRAM]: null,
  [FORM_FIELDS.REDDIT]: null,
  [FORM_FIELDS.GITHUB]: null,
  [FORM_FIELDS.DESCRIPTION]: null,
};

const preSaleLaunchForm = {
  // Token Info
  [FORM_FIELDS.NAME]: null,
  [FORM_FIELDS.SYMBOL]: null,
  [FORM_FIELDS.DECIMALS]: null,
  [FORM_FIELDS.TOTAL_SUPPLY]: null,
  [FORM_FIELDS.BALANCE_OF]: null,
  [FORM_FIELDS.UUID]: null,
  // Launchpad Info
  [FORM_FIELDS.PAYMENT_TOKEN]: null,
  [FORM_FIELDS.LAUNCH_TOKEN]: null,
  [FORM_FIELDS.LAUNCH_TOKEN_AMOUNT]: null,
  [FORM_FIELDS.LAUNCH_PRICE]: null,
  [FORM_FIELDS.LISTING_PRICE]: null,
  [FORM_FIELDS.LAUNCH_START_TIME]: null,
  [FORM_FIELDS.LAUNCH_END_TIME]: null,
  [FORM_FIELDS.LAUNCH_LIQUIDITY_PERCENTAGE]: null,
  [FORM_FIELDS.LAUNCH_LIQUIDITY_LOCKUP_DURATION]: null,
  [FORM_FIELDS.LAUNCH_SOFT_CAP]: null,
  [FORM_FIELDS.LAUNCH_HARD_CAP]: null,
  //[FORM_FIELDS.LAUNCH_MIN_CONTRIBUTION]: null,
  //[FORM_FIELDS.LAUNCH_MAX_CONTRIBUTION]: null,
  [FORM_FIELDS.REFUND_TYPE]: null,
  [FORM_FIELDS.VESTING_ENABLED]: null,
  [FORM_FIELDS.TGE_UNLOCK_PERCENTAGE]: null,
  [FORM_FIELDS.VESTING_START]: null,
  [FORM_FIELDS.VESTING_DURATION]: null,
  [FORM_FIELDS.VESTING_UNLOCK_UNIT]: null,
  // Documents
  [FORM_FIELDS.WHITE_PAPER]: null,
  [FORM_FIELDS.KYC]: null,
  [FORM_FIELDS.AUDIT]: null,
  // Socials
  [FORM_FIELDS.WEBSITE]: null,
  [FORM_FIELDS.LOGO_FILE]: null,
  [FORM_FIELDS.BANNER_FILE]: null,
  [FORM_FIELDS.TWITTER]: null,
  [FORM_FIELDS.TELEGRAM]: null,
  [FORM_FIELDS.DISCORD]: null,
  [FORM_FIELDS.INSTAGRAM]: null,
  [FORM_FIELDS.REDDIT]: null,
  [FORM_FIELDS.GITHUB]: null,
  [FORM_FIELDS.DESCRIPTION]: null,
};

const createTokenForm = {
  [FORM_FIELDS.TOKEN_TYPE]: TOKEN_FACTORIES.StandardToken,
  [FORM_FIELDS.NAME]: null,
  [FORM_FIELDS.SYMBOL]: null,
  [FORM_FIELDS.DESCRIPTION]: null,
  [FORM_FIELDS.DECIMALS]: null,
  [FORM_FIELDS.TOTAL_SUPPLY]: null,
  [FORM_FIELDS.IS_ANTI_BOT]: null,
  [FORM_FIELDS.ROUTER]: pulsexRouter,
  [FORM_FIELDS.LGT_YIELD_FEE]: null,
  [FORM_FIELDS.LGT_LIQUIDITY_FEE]: null,
  [FORM_FIELDS.LGT_CHARITY_ADDRESS]: null,
  [FORM_FIELDS.LGT_CHARITY_FEE]: null,
  [FORM_FIELDS.REWARD_TOKEN]: null,
  [FORM_FIELDS.BT_MIN_TOKENS]: null,
  [FORM_FIELDS.BT_TOKEN_REWARD_FEES]: null,
  [FORM_FIELDS.BT_AUTO_ADD_LIQUIDITY]: null,
  [FORM_FIELDS.BT_MARKETING_FEE]: null,
  [FORM_FIELDS.BT_MARKETING_WALLET]: null,
  [FORM_FIELDS.BBBT_LIQUIDITY_FEE]: null,
  [FORM_FIELDS.BBBT_BUYBACK_FEE]: null,
  [FORM_FIELDS.BBBT_REFLECTION_FEE]: null,
  [FORM_FIELDS.BBBT_MARKETING_FEE]: null,
};
  

export const formSlice = createSlice({
  name: 'form',
  initialState: {
      [FORM_TYPES.FAIR_LAUNCH]: fairLaunchForm,
      [FORM_TYPES.PRE_SALE_LAUNCH]: preSaleLaunchForm,
      [FORM_TYPES.CREATE_TOKEN]: createTokenForm
  },
  reducers: {
    setForm: (state, action) => {
        const { formType, form } = action.payload;
        state[formType] = form;
    },
    setFormField: (state, action) => {
        const { formType, field, value } = action.payload;
        state[formType][field] = value;
    }
  }
})

export const { setForm, setFormField } = formSlice.actions

export default formSlice.reducer
