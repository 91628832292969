import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  upcoming: [],
  active: [],
  closed: [],
  by_user: [],
  by_id: {}
};

const launchpadSlice = createSlice({
  name: "launchpads",
  initialState,
  reducers: {
    setUpcoming: (state, { payload }) => {
      state.upcoming = payload;
    },
    setActive: (state, { payload }) => {
      state.active = payload;
    },
    setClosed: (state, { payload }) => {
      state.closed = payload;
    },
    setByUser: (state, { payload }) => {
      state.by_user = payload;
    },
    setById: (state, { payload }) => {
      state.by_id = payload;
    }
  },
  extraReducers: {},
});

export const { 
  setUpcoming,
  setActive,
  setClosed,
  setByUser,
  setById
} = launchpadSlice.actions;
export default launchpadSlice.reducer;
