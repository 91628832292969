import { PUBLIC_ENVS } from "../constants/index";
const publicEnv = process.env.NEXT_PUBLIC_ENV;

const API_URL = publicEnv === PUBLIC_ENVS.DEV ?
    "http://localhost:15001/development" :
      publicEnv === PUBLIC_ENVS.STAGE ?
    "https://z5rw8dmgzi.execute-api.us-east-1.amazonaws.com/stage" 
      :
    "https://nlncrssr9k.execute-api.us-east-1.amazonaws.com/production" 

const API_ENDPOINTS = {
  USER: {
    GET: {
      url: "/api/user/get",
      method: "GET",
    },
    SET: { 
      USERNAME: {
        url: "/api/user/set/username",
        method: "POST",
      },
      AVATAR: {
        url: "/api/user/set/avatar", 
        method: "POST",
      },
    }
  },
  LAUNCH_TOKEN: {
    REGISTER: {
      url: "/api/launchtoken/register",
      method: "POST",
    },
    SET_LOGO: {
      url: "/api/launchtoken/setlogo",
      method: "POST",
    },
    GET_ALL: {
      url: "/api/launchtoken/get/all",
      method: "GET",
    },
    GET_BY_USER: {
      url: "/api/launchtoken/get/user",
      method: "GET",
    }
  },
  SACRIFICE: {
    GET_CLAIM_DATA: {
      url: "/api/sacrifice/get/claimdata",
      method: "GET",
    },
    CONFIRM_SACRIFICE_CLAIM: {
      url: "/api/sacrifice/confirm/claim",
      method: "POST",
    }
  },
  FAIRLAUNCH_API: {
    REGISTER: {
      url: "/api/fairlaunch/register",
      method: "POST",
    },
    SET_LOGO: {
      url: "/api/fairlaunch/set/logo",
      method: "POST",
    },
    SET_BANNER: {
      url: "/api/fairlaunch/set/banner",
      method: "POST",
    },
    GET_UPCOMING_LAUNCHPADS: {
      url: "/api/fairlaunch/upcoming",
      method: "GET",
    },
    GET_ACTIVE_LAUNCHPADS: {
      url: "/api/fairlaunch/active",
      method: "GET",
    },
    GET_CLOSED_LAUNCHPADS: {
      url: "/api/fairlaunch/closed",
      method: "GET",
    },
    GET_LAUNCHPADS_BY_USER: {
      url: "/api/fairlaunch/user",
      method: "GET",
    },
    GET_LAUNCHPAD_BY_ID: {
      url: "/api/fairlaunch/id",
      method: "GET",
    },
  },
  PRESALE: {
    REGISTER: {
      url: "/api/presale/register",
      method: "POST",
    },
    SET_LOGO: {
      url: "/api/presale/set/logo",
      method: "POST",
    },
    SET_BANNER: {
      url: "/api/presale/set/banner",
      method: "POST",
    },
    GET_UPCOMING_LAUNCHPADS: {
      url: "/api/presale/upcoming",
      method: "GET",
    },
    GET_ACTIVE_LAUNCHPADS: {
      url: "/api/presale/active",
      method: "GET",
    },
    GET_CLOSED_LAUNCHPADS: {
      url: "/api/presale/closed",
      method: "GET",
    },
    GET_LAUNCHPADS_BY_USER: {
      url: "/api/presale/user",
      method: "GET",
    },
    GET_LAUNCHPAD_BY_ID: {
      url: "/api/presale/id",
      method: "GET",
    },
    UPDATE: {
      STATUS: {
        url: "/api/presale/update/status",
        method: "POST"
      }
    }
  }
} 

module.exports = {
  API_URL,
  API_ENDPOINTS,
}