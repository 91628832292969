import { Chain } from 'wagmi'
 
export const pulsechaintestnet = {
  id: 943,
  name: 'PulseChain Testnet V4',
  network: 'PulseChain Testnet V4',
  nativeCurrency: {
    decimals: 18,
    name: 'Test Pulsechain Token',
    symbol: 'tPLS',
  },
  rpcUrls: {
    public: { http: ['https://rpc.v4.testnet.pulsechain.com'] },
    default: { http: ['https://rpc.v4.testnet.pulsechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'V4', url: 'https://scan.v4.testnet.pulsechain.com' },
    default: { name: 'V4', url: 'https://scan.v4.testnet.pulsechain.com' },
  },
}