import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  upcoming: [],
  active: [],
  closed: [],
  by_user: [],
  by_id: {}
};

const presalesSlice = createSlice({
  name: "presales",
  initialState,
  reducers: {
    setUpcomingPresale: (state, { payload }) => {
      state.upcoming = payload;
    },
    setActivePresale: (state, { payload }) => {
      state.active = payload;
    },
    setClosedPresale: (state, { payload }) => {
      state.closed = payload;
    },
    setPresaleByUser: (state, { payload }) => {
      state.by_user = payload;
    },
    setPresaleById: (state, { payload }) => {
      state.by_id = payload;
    }
  },
  extraReducers: {},
});

export const { 
  setUpcomingPresale,
  setActivePresale,
  setClosedPresale,
  setPresaleByUser,
  setPresaleById
} = presalesSlice.actions;
export default presalesSlice.reducer;
