import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allTokens: [],
  userTokens: []
};

const launchTokensSlice = createSlice({
  name: "launchTokens",
  initialState,
  reducers: {
    setAllTokens: (state, { payload }) => {
      state.allTokens = payload;
    },
    setUserTokens: (state, { payload }) => {
      state.userTokens = payload;
    }
  },
  extraReducers: {},
});

export const { setAllTokens, setUserTokens } = launchTokensSlice.actions;
export default launchTokensSlice.reducer;