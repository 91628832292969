export const FORM_TYPES = {
  FAIR_LAUNCH: 'FAIR_LAUNCH',
  PRE_SALE_LAUNCH: 'PRE_SALE_LAUNCH',
  CREATE_TOKEN: "CREATE_TOKEN"
}

export const FORM_FIELDS = {
  // Launch token
  TOKEN_TYPE: "tokenType",
  NAME: "tokenName",
  SYMBOL: "tokenSymbol",
  DESCRIPTION: "description",
  DECIMALS: "tokenDecimals",
  TOTAL_SUPPLY: "tokenSupply",
  BALANCE_OF: "balanceOf",
  IS_ANTI_BOT: "isAntiBot",
  ROUTER: "router",
  LGT_YIELD_FEE: "lgtYieldFee",
  LGT_LIQUIDITY_FEE: "lgtLiquidityFee",
  LGT_CHARITY_ADDRESS: "lgtCharityAddress",
  LGT_CHARITY_FEE: "lgtCharityFee",
  REWARD_TOKEN: "rewardToken",
  BT_MIN_TOKENS: "btMinTokens",
  BT_TOKEN_REWARD_FEES: "btTokenRewardFees",
  BT_AUTO_ADD_LIQUIDITY: "btAutoAddLiquidity",
  BT_MARKETING_FEE: "btMarketingFee",
  BT_MARKETING_WALLET: "btMarketingWallet",
  BBBT_LIQUIDITY_FEE: "bbbtLiquidityFee",
  BBBT_BUYBACK_FEE: "bbbtBuybackFee",
  BBBT_REFLECTION_FEE: "bbbtReflectionFee",
  BBBT_MARKETING_FEE: "bbbtMarketingFee",
  UUID: "uuid",

  // Launchpad
  PAYMENT_TOKEN: "paymentToken",
  LAUNCH_TOKEN: "launchToken",
  LAUNCH_START_TIME: "launchStartTime",
  LAUNCH_END_TIME: "launchEndTime",
  LAUNCH_LIQUIDITY_PERCENTAGE: "launchLiquidityPercentage",
  LAUNCH_LIQUIDITY_LOCKUP_DURATION: "launchLiquidityLockupDuration",
  LAUNCH_TOKEN_AMOUNT: "launchTokenAmount",
  LAUNCH_PRICE: "launchTokenRate",
  LISTING_PRICE: "launchListingRate",
  LAUNCH_SOFT_CAP: "launchSoftCap",
  LAUNCH_HARD_CAP: "launchHardCap",
  LAUNCH_MIN_CONTRIBUTION: "launchMinContribution",
  LAUNCH_MAX_CONTRIBUTION: "launchMaxContribution",
  REFUND_TYPE: "refundType",
  VESTING_ENABLED: "vestingEnabled",
  TGE_UNLOCK_PERCENTAGE: "tgeUnlockPercentage",
  VESTING_START: "vestingStart",
  VESTING_DURATION: "vestingDuration",
  VESTING_UNLOCK_UNIT: "vestingUnlockUnit",

  // Documents
  WHITE_PAPER: "whitePaper",
  KYC: "kyc",
  AUDIT: "audit",

  // Socials
  WEBSITE: "website",
  LOGO_FILE: "logoFile",
  BANNER_FILE: "bannerFile",
  TWITTER: "twitter",
  TELEGRAM: "telegram",
  DISCORD: "discord",
  INSTAGRAM: "instagram",
  REDDIT: "reddit",
  GITHUB: "github",
  DESCRIPTION: "description",
}

export const REGEX = {
  website: /^(https?:\/\/)?(www\.|app\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  logoUrl: /^(https?:\/\/)?(www\.|app\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  twitter: /^(https?:\/\/)?(www\.|app\.)?twitter\.com\/[a-zA-Z0-9_]{1,50}$/,
  telegram: /^(https?:\/\/)?(www\.|app\.)?t\.me\/[a-zA-Z0-9_]{1,50}$/,
  discord: /^(https?:\/\/)?(www\.|app\.)?discord\.com\/invite\/[a-zA-Z0-9_]{1,50}$/,
  instagram: /^(https?:\/\/)?(www\.|app\.)?instagram\.com\/[a-zA-Z0-9_]{1,50}$/,
  reddit: /^(https?:\/\/)?(www\.|app\.)?reddit\.com\/r\/[a-zA-Z0-9_]{1,50}$/,
  github: /^(https?:\/\/)?(www\.|app\.)?github\.com\/.*/,
  description: /^.{1,1000}$/,
  onlyText: /^[a-zA-Z_!@#$&*0-9][a-zA-Z_\s!@#$&*0-9]{0,62}[a-zA-Z_!@#$&*\s0-9]$/,
  ethereumAddress: /^0x[a-fA-F0-9]{40}$/,
}

export const PUBLIC_ENVS = {
  DEV: "development",
  STAGE: "stage",
  PROD: "production",
}
