import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api";
import userSlice from "./user.slice";
import fromSlice from "./form.slice"
import launchTokensSlice from "./launchTokens.slice";
import launchpadSlice from "./launchpads.slice";
import presalesSlice from "./presales.slice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    form: fromSlice,
    launchToken: launchTokensSlice,
    launchpad: launchpadSlice,
    presale: presalesSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});
