import { ethers } from "ethers";
import { TOKEN_FACTORIES } from "./web3_contracts";
import { PUBLIC_ENVS } from "../constants/index";

const publicEnv = process.env.NEXT_PUBLIC_ENV;
if (!publicEnv) {
  throw new Error("NEXT_PUBLIC_ENV is not set");
}
if (publicEnv !== PUBLIC_ENVS.STAGE && publicEnv !== PUBLIC_ENVS.PROD && publicEnv !== PUBLIC_ENVS.DEV) {
  throw new Error("NEXT_PUBLIC_ENV is not set to a valid value");
}
console.log("NEXT_PUBLIC_ENV", publicEnv)

let settings;
if (publicEnv === PUBLIC_ENVS.DEV) {
  settings = {
    network: "sepolia",
    pulsexRouter: "0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02",
    tokenMintFee: ethers.utils.parseEther("0.0000001"),
    launchpadFee: ethers.utils.parseEther("0.0000002"),
    feeReceiver: "0x399b581FE98332283068c7498882aAedCe23953c",
    minStartEndDelta: 0, // unit = seconds
    minLiq: 60,
    minLock: 90,
    contractTimeOffset: 0
  }
} else if (publicEnv === PUBLIC_ENVS.STAGE) {
  settings = {
    network: "pulsechaintestnet",
    pulsexRouter: "0xDaE9dd3d1A52CfCe9d5F2fAC7fDe164D500E50f7",
    tokenMintFee: ethers.utils.parseEther("0.0000001"),
    launchpadFee: ethers.utils.parseEther("0.0000002"),
    feeReceiver: "0x399b581FE98332283068c7498882aAedCe23953c",
    minStartEndDelta: 0, // unit = seconds
    minLiq: 60,
    minLock: 90,
    contractTimeOffset: 0
  }
} else {
  settings = {
    network: "pulsechain",
    pulsexRouter: "0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02",
    tokenMintFee: ethers.utils.parseEther("0"), // 50$
    launchpadFee: ethers.utils.parseEther("0"), // 150$
    feeReceiver: "0x399b581FE98332283068c7498882aAedCe23953c",
    minStartEndDelta: 3600 * 12, // unit = seconds
    minLiq: 60,
    minLock: 90,
    contractTimeOffset: 0
  }
}

export const { 
  network, 
  pulsexRouter, 
  feeReceiver,
  tokenMintFee,
  launchpadFee,
  minStartEndDelta,
  minLiq,
  minLock,
  priceDiscoveryPhases,
  contractTimeOffset
} = settings;

// Chain
export const tokenFactoryUUID = {
  [TOKEN_FACTORIES.BabyToken]: 759874379387,
  [TOKEN_FACTORIES.BuybackBabyToken]: 431323213131,
  [TOKEN_FACTORIES.LiquidityGeneratorToken]: 324387873487287,
  [TOKEN_FACTORIES.StandardToken]: 857488486266
} 

// Chat
export const defaultChatId = "0"

// Staking estimation
export const plfPrice = 0.0002277; // in USD
export const expectedDailyVolume = 1000; // in USD
export const stakingRewardsTaxRate = 0.02; // in percentage
export const estimatePeriod = 365; // in days

// Launchpad
export const WINNER_FEE = 2000; // 20% in BPS