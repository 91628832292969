import { checkWindow } from "@/lib/functions/_helpers.lib";
import "@/styles/global.scss";
import { userData } from "@/types/common.type";
import ToastifyProvider from "@/ui/toastify/ToastifyProvider";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import dynamic from "next/dynamic";
import nookies from "nookies";
import React, { useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { WagmiConfig } from "wagmi";
import { ethereumClient, projectId, wagmiClient } from "../utils/wagmi";
import { useWeb3Modal, useWeb3ModalTheme, Web3Modal } from "@web3modal/react";
import { Toaster } from "react-hot-toast";
import appSettings from "../config/app"

const MuiTheme = dynamic(() => import("@/themes/index"), { ssr: true });

/**
 * It suppresses the useLayoutEffect warning when running in SSR mode
 */
function fixSSRLayout() {
  // suppress useLayoutEffect (and its warnings) when not running in a browser
  // hence when running in SSR mode
  if (!checkWindow()) {
    React.useLayoutEffect = () => {
      // console.log("layout effect")
    };
  }
}

const queryClient = new QueryClient();

interface CustomAppProps extends AppProps {
  user: userData | null;
  hasToken?: boolean;
}

export default function CustomApp({
  Component,
  pageProps,
  hasToken,
  user,
}: CustomAppProps) {
  fixSSRLayout();
  const [ready, setReady] = useState(false);

  const { setTheme } = useWeb3ModalTheme();
  useEffect(() => {
    setReady(true);
    setTheme({
      themeVariables: {
        "--w3m-z-index": "999",
      },
    });
  }, []);
  const { isOpen } = useWeb3Modal();
  useEffect(()=>{
    var style = document.createElement( 'style' )
    style.innerHTML = '#w3m-modal { overflow: inherit; }'
    document.getElementsByTagName("w3m-modal")?.[0].shadowRoot?.appendChild( style )
  },[isOpen]);
  return (
    <>
      {ready ? (
        <WagmiConfig client={wagmiClient}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <ToastifyProvider>
                  <MuiTheme>
                    <Component {...pageProps} />
                    <Toaster
                      toastOptions={{
                        style: {
                          borderRadius: "0",
                          background: "#0E0E0E",
                          color: "#fff",
                          border: "1px solid #757575",
                          maxWidth: "fit-content",
                        },
                      }}
                    />
                  </MuiTheme>
                </ToastifyProvider>
              </Hydrate>
            </QueryClientProvider>
          </Provider>
        </WagmiConfig>
      ) : null}
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} enableNetworkView={true} />
    </>
  );
}

/* Getting the current user from the server and passing it to the client. */
CustomApp.getInitialProps = async (context: AppContext) => {
  // // const client = initializeApollo({ headers: context.ctx.req?.headers });
  // const { data } = await client.query({
  //   query: CURRENT_USER_QUERY,
  // });
  // // resetServerContext();
  const appProps = await App.getInitialProps(context);

  return { ...appProps };
};
