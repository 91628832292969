import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  sacrifice: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setSacrifice: (state, { payload }) => {
      state.sacrifice = payload;
    }
  },
  extraReducers: {},
});

export const { setUser, setSacrifice } = userSlice.actions;
export default userSlice.reducer;
