import { configureChains, createClient } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { pulsechaintestnet } from "./pulsechaintestnet";
import { pulsechain} from "./pulsechain";
import { mainnet, sepolia, localhost } from "wagmi/chains";
import { network } from "config/app"

const networks = {
  mainnet,
  sepolia,
  localhost,
  pulsechain,
  pulsechaintestnet,
}

if (!process.env.NEXT_PUBLIC_WEB3_WALLET_PROJECT_ID) {
  throw new Error(
    "You need to provide NEXT_PUBLIC_WEB3_WALLET_PROJECT_ID env variable"
  );
}

export const projectId = process.env.NEXT_PUBLIC_WEB3_WALLET_PROJECT_ID;
// 2. Configure wagmi client
const chains = [networks[network]];

const { provider } = configureChains(chains, [
  w3mProvider({ version: 1, projectId, chains }),
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1, chains, projectId }),
  provider,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiClient, chains);
